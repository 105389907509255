<template>
  <div
    class="themelogout"
    :style="{ background: $store.getters.getColor('bgColorSecundary') }"
  >
    <v-container class="pa-8">
      <v-row no-gutters class="mb-10">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <img
            v-if="$store.getters.getLogoClaro"
            :src="$store.getters.getLogoClaro"
            width="110"
            class="mt-10 mb-8"
          />
          <h1 v-else class="white--text">{{ $store.getters.getAppName }}</h1>
        </v-col>
      </v-row>
      <v-row no-gutters justify="center">
        <v-col cols="12" sm="8">
          <h1 class="white--text mb-5 title font-weight-bold">
            Informe seu CPF
          </h1>
          <validation-observer ref="form">
            <v-form @submit.prevent="submit()">
              <v-row>
                <v-col>
                  <base-text-field
                    :autoBlur="14"
                    rules="required|cpf"
                    inputmode="numeric"
                    type="tel"
                    id="cpf"
                    name="CPF"
                    label="Digite aqui..."
                    clearable
                    v-mask="'###.###.###-##'"
                    outlined
                    maxlength="14"
                    v-model="form.cpf"
                    :darkTheme="true"
                  ></base-text-field>
                </v-col>
              </v-row>
              <v-btn
                block
                large
                type="submit"
                elevation="10"
                color="white"
                class="text-button mb-1 mt-4 font-weight-bold"
                :loading="loading"
              >
                <b class="primary--text">Continuar</b>
                <span slot="loader">
                  <v-progress-circular
                    indeterminate
                    :width="3"
                    color="primary"
                  ></v-progress-circular>
                </span>
              </v-btn>
            </v-form>
            <div class="d-flex justify-center">
              <v-btn
                text
                large
                :to="{ name: 'login' }"
                color="primary"
                class="text-none font-weight-bold"
                >Já sou cadastrado</v-btn
              >
            </div>
          </validation-observer>
        </v-col>
      </v-row>
      <v-spacer style="width: 100%"></v-spacer>
    </v-container>
    <v-dialog v-model="usuarioExiste" max-width="290">
      <v-card>
        <v-card-title class="headline">Usuário ativo</v-card-title>
        <v-card-text>CPF informado já possui cadastro ativo!</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            block
            text
            color="primary darken-2"
            @click="usuarioExiste = false"
            >Entendi</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import BaseTextField from "@/components/BaseTextField.vue";
import usuarioService from "@/services/usuarioService";
import filters from "@/filters/index";

export default {
  data() {
    return {
      usuarioExiste: false,
      loading: false,
      form: {
        cpf: "",
      },
    };
  },
  components: {
    BaseTextField,
  },
  methods: {
    ...mapActions({
      validaCorretorPorCpf: "cadastroCorretor/validaCorretorPorCpf",
      setCorretor: "cadastroCorretor/setCorretor",
      setEndereco: "cadastroCorretor/setEndereco",
    }),
    async dadosSerasa() {
      try {
        const integracaoZooxSmart = await usuarioService.getPropriedadeConfig(
          "integracaoZooxSmart"
        );

        let dados;
        if (integracaoZooxSmart.data) {
          dados = await usuarioService.buscarZooxsmart({
            cpf: this.form.cpf,
          });
        } else {
          dados = await usuarioService.buscarSerasa({
            cpf: this.form.cpf,
            rotina: "Consultar CPF do corretor",
          });
        }
        return dados;
      } catch (error) {
        return false;
      }
    },
    async submit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      this.loading = true;
      try {
        const data = await this.validaCorretorPorCpf(this.form);
        if (data.usuarioValidado && !data.etapaCadastroInicial) {
          this.usuarioExiste = true;
        } else if (data) {
          const { corretor, endereco, listaAtencao } = data;
          if (listaAtencao) {
            this.$root.$snackBar.open({
              color: "error",
              message:
                "Você não tem permissão para acessar. Procure seu coordenador comercial.",
            });
            return;
          }
          const dados = await this.dadosSerasa();
          if (dados) {
            dados.nascimento = filters.convertDateOutput(dados.dataNascimento);
          }
          this.setCorretor({ ...corretor, ...dados });
          this.setEndereco({ ...endereco });
          this.$router.push({ name: "cadastro" });
        } else {
          this.$root.$snackBar.open({
            color: "error",
            message: "CPF não encontrado. Entre em contato com sua corretora",
          });
        }
      } catch (error) {
        this.$root.$snackBar.open({ color: "error", message: error.message });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
